// changes the default icon color
$ag-icons-path: "../node_modules/ag-grid-community/src/styles/ag-theme-balham/icons/";
$icon-color: #2d69eb;

// changes the icon secondary color (checkbox background)
// $alt-icon-color: #2d69eb;

// changes the selected checkbox check mark color
// $accent-color: #fffff1;

$header-height: 43px;
$header-background-color: #fff;
$row-height: 33px;
$row-border-width: 0.5px;
$hover-color: #e0e9fc;
$background-color: #fff;
$odd-row-background-color: #fff;

$ag-range-selected-color-1: rgb(250, 235, 215);
$ag-range-selected-color-2: darken($ag-range-selected-color-1, 10%);
$ag-range-selected-color-3: darken($ag-range-selected-color-1, 20%);
$ag-range-selected-color-4: darken($ag-range-selected-color-1, 30%);

@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
