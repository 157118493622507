.App {
  text-align: center;
  font-size: 16px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

html {
  font-size: 16px;
}

.t-a-r {
  text-align: right;
}

.t-a-c {
  text-align: center;
}

.m-t-3 {
  margin-top: 1rem;
}

.MuiSvgIcon-root {
  height: 0.8em !important;
  width: 0.8em !important;
}

.ag-header-cell-label .ag-header-cell-text {
  overflow: visible !important;
  text-overflow: unset !important;
  white-space: normal !important;
  text-align: center;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 10px 0 !important;
  background-color: #2d69eb !important;
  color: white !important;
  padding: 10px !important;
  border-radius: 8px !important;
}

.Mui-expanded > span > svg {
  color: #fff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #2d69eb !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.cluster-legend-group {
  justify-content: center;
}

.cluster-legend-group > label {
  border: solid 1px #e2e2e2;
  border-radius: 10px;
  padding: 0 10px 0 0;
}

.cluster-legend-group > label > .PrivateSwitchBase-root-177 {
  padding: 5px;
}

.cluster-legend-group > label > .MuiTypography-body1 {
  font-size: 0.8rem !important;
  line-height: 1 !important;
}

/* material UI Rangle slider bubble css*/
/* .PrivateValueLabel-offset-179 {
  top: -50px !important;
  left: calc(-50% + -12px) !important;
  font-size: 0.65rem !important;
}
.PrivateValueLabel-circle-180 {
  width: 50px !important;
  height: 50px !important;
} */

/*  Month Picker */
.react-daterange-picker__wrapper {
  width: 375px;
  height: 36px;
  border: 1px solid #dbdbdb !important;
}
.react-daterange-picker__inputGroup {
  padding-left: 18px !important;
  font-size: 15px;
}
.react-daterange-picker__range-divider {
  line-height: 30px;
}
.react-daterange-picker__calendar-button {
  display: none !important;
}

/**********************************/

/* Material UI Dialog Styles*/
#responsive-dialog-title,
#responsive-dialog-title .MuiIconButton-root {
  background-color: #2d69eb;
  color: white;
}

.MuiTypography-h6 {
  font-size: 14px !important;
  font-weight: 700 !important;
}

/* react select styles for options over flow*/

.css-26l3qy-menu {
  z-index: 10000 !important;
}

.css-df17o1 {
  z-index: 10000 !important;
}

.mapping-non-comp .css-1ml51p6-MenuList {
  max-height: 120px !important;
}

.css-1r4vtzz {
  height: 36px !important;
  padding: 4px 10px 5px 10px !important;
  margin-top: 5px !important;
  border-radius: 6px !important;
  border: 1px solid #c7c7c7 !important;
  width: 100% !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}

.css-48ayfv {
  height: 36px !important;
  margin-top: 5px !important;
  border-radius: 6px !important;
  border: 1px solid #c7c7c7 !important;
  padding: 4px 10px 5px 10px !important;
  width: 100% !important;
  /* box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07); */
}

.css-1gpjby2 {
  margin-left: 26px !important;
  margin-right: 15px;
}

.css-1v99tuv {
  color: #3e3e3e !important;
  font-size: 14px !important;
  padding-left: 2px !important;
  top: 37% !important;
}

.css-1rhbuit-multiValue {
  min-width: auto !important;
}

.css-1hwfws3 {
  flex-wrap: nowrap !important;
}

.css-15brt4p {
  /* display: none !important; */
  height: 30px !important;
  border-radius: 5px !important;
  border-width: 0.5px !important;
  min-width: 210px !important;
}

.css-1kuo3lu {
  margin-bottom: 5px;
  /* border:1px solid gray !important; */
  height: 30px !important;
  min-height: 32px !important;
  border: none !important;
  /* border-width: 0.5px !important; */
  border-radius: 5px !important;
  min-width: 210px !important;
  /* border-color: gray !important; */
}

.css-1g6gooi {
  margin-top: -8px !important;
}

.css-lc91z5 {
  padding: 1px 12px !important;
  font-size: 12px !important;
  font-weight: bold;
  color: #3e3e3e !important;
}

.css-19lyk4y {
  background-color: #007bffe8 !important;
  color: #fffbfb !important;
  font-size: 13px !important;
  padding: 1px 12px !important;
}

.css-1492t68 {
  top: 39% !important;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.css-df17o1 {
  position: fixed !important;
}

/************* Attribute Selection **************/

.attr-sel-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.attribute-clusturing-select {
  width: 12rem;
}

.info-Items {
  display: flex;
  margin-top: 2rem;
}

.chart-container {
  display: flex;
  margin-top: 2rem;
}

/* Button { 
  color: #1D9AF2;
  background-color: #292D3E;
  border: 1px solid #1D9AF2;
  border-radius: 4px;
  padding: 0 15px;
  cursor: pointer;
  height:32px;
  font-size:14px;
  transition: all 0.2s ease-in-out;
  }
Button:hover { 
  animation: swing 1s ease;
  animation-iteration-count: 1;
} */
/* @keyframes swing { 
  15% { 
    transform: translateX(5px);
  } 
  30% { 
    transform: translateX(-5px);
  } 
  50% { 
    transform: translateX(3px);
  } 
  65% { 
    transform: translateX(-3px);
  } 
  80% { 
    transform: translateX(2px);
  } 
  100% { 
    transform: translateX(0);
  }    
}  */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.App-link {
  color: #61dafb;
}

/* --------------------------------------------STEPPER CSS-------------------------------------- */
.MuiStepButton-root {
  padding: 10px !important;
}

.MuiStepConnector-line {
  display: block;
}

.MuiStepConnector-alternativeLabel {
  top: 12px;
  left: calc(-50% + 2rem);
  right: calc(50% + 2rem) !important;
  position: absolute;
}

.MuiStepIcon-root {
  font-size: 2.5rem !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #2c6ae8 !important;
  font-size: 2.5rem !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #2c6ae8 !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 4px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #2c6ae8 !important;
}

.MuiButton-contained {
  float: right !important;
}

.MuiButton-root {
  color: white !important;
  background-color: #2c6ae8 !important;
  margin: 0.2rem !important;
  text-transform: capitalize !important;
}

.MuiButton-root.Mui-disabled {
  color: black !important;
  background-color: grey !important;
}

/* ---------------------------------------------- CARD CSS --------------------------------------- */
.subCard {
  margin: 1rem;
  box-sizing: border-box;
  background-color: #f2f3f5;
  padding: 0.5rem;
  box-shadow: 0px 0px 12px -1px rgba(191, 191, 191, 1);
}

.cardContainer {
  font-size: 0.9rem;
  width: 100% !important;
  padding: 2rem 1.5rem;
  /* margin: 15px; */
  box-sizing: border-box;
  background-color: white;
}

.row {
  display: flex;
  flex-wrap: inherit !important;
}

.card-header {
  font-size: 1.2rem;
  background-color: #286ee4;
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ===========================================customcss.css================================ */
/*
    CUSTOM STYLES
*/
body {
  font-family: Helvetica !important;
  background: #f9f9f9;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 0.2rem 1rem 0.2rem 2rem !important;
  background: #ffffff !important;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #c8ced3;
  right: 0;
  left: 0;
  z-index: 800;
  position: fixed !important;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

img#imgCenterJoann {
  margin-left: 40%;
}

/* Tippy Styles */

.tippy-popper {
  z-index: 10001 !important;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  /* margin-top: 44px; */
  min-width: 60px;
  max-width: 80px;
  background: #000000;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
  color: #a2a2a2;
  transition: all 200ms ease-in;
  height: 100%;
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
}

.sideBarList1 {
  margin-top: 10px;
}

#sidebar.active ul li a span {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms ease-in;
}

#sidebar ul li a span {
  opacity: 0;
  width: 200px;
  transform: translateX(-100%);
  transition: none;
  transition: none 0s ease 0s !important;
  position: absolute;
}

i.fa.fa-angle-left.innerIcoTop {
  padding-left: 23px;
  font-size: 21px;
  /* margin-top: 407px; */
  color: #565454;
  font-weight: bold;
}

i.fa.fa-angle-right.innerIcoTop2 {
  padding-left: 23px;
  font-size: 21px;
  /* margin-top: 407px; */
  color: #565454;
  font-weight: bold;
}

#sidebar.active {
  min-width: 200px;
  max-width: 200px;
  z-index: 500;
  position: fixed;
  height: 100%;
}

li.nav-item {
  padding-right: 22px;
}

.arrowBottom {
  position: absolute;
  bottom: 10px;
}

.hidetext {
  display: none;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

#sidebar ul li a {
  padding: 10px 0;
  font-size: 0.9rem;
  display: block;
  color: #4c4c4c;
  text-align: center;
}

#sidebar.active ul li a {
  text-align: left !important;
}

#sidebar ul li a:hover {
  background: #e0e0e0 !important;
  border-right: 2px solid #28a745 !important;
}

.sidebar-header {
  margin-top: 65px;
}

#sidebar ul li a i {
  font-size: 20px;
  margin: 0 16px;
}

#sidebar ul li.active > a,
a[aria-expanded="false"] {
  color: #2d69eb !important;
  background: #e0e9fc !important;
  border-right: 2px solid #2d69eb !important;
}

.icon-styling {
  margin-right: 12px;
  margin-left: 12px;
  font-size: 19px !important;
  opacity: 0.8;
  color: #ffffff;
}

.icon-styling:hover {
  color: #f7eeee !important;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.img-styling {
  height: 17px;
}

.img2-styling {
  height: 17px;
}

/* ------------------------side bar end -----------------*/
.card {
  border-radius: 8px !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  border: none !important;
}

/*------------------ScrollBar Styles------------*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #989898;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#subcontent {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  width: 95%;
  /* margin: 30px auto; */
  /* margin: 4.5rem auto; */
  margin-top: 4.5rem;
  padding-right: 1.5rem;
  padding-left: 55px;
  /* margin-top: 5px; */
}

.breadcrumbsDiv {
  margin-top: 55px;
  margin-left: 55px;
}

.breadcrumb {
  font-size: 14px;
  background: white !important;
  border-bottom: 1px solid #c8ced3;
}

/* .collapseButton{

} */

p.containerText {
  /* margin-left: 90px; */
  opacity: 0.5;
  font-family: Helvetica;
  font-size: 1rem;
  color: #000000;
  margin-top: 78px;
}

#main-container {
  width: 100%;
  padding: 80px 20px 20px 80px;
  background: #f5f5f5;
  transition: all 0.3s;
  height: 100vh;
}

/* ================datePicker CSS =================== */
.DateInput_input {
  font-weight: 100 !important;
  font-size: 0.9rem !important;
  text-align: center !important;
  padding: 0.2rem !important;
  min-height: 36px !important;
}

.DateInput {
  width: 45% !important;
}

.DateRangePickerInput__withBorder {
  border-radius: 4px !important;
}

/* 
.tippy-content{
  z-index: 1000000;
} */

/* miscellaneous css classes*/
.pointer {
  cursor: pointer;
}

.textColor {
  color: #2d69eb;
}

/*Dashboard CSS*/
.dashboardLabelAndButton {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
}

/*  end  */

/* Auto APX */
.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.clusterSummaryTable {
  position: fixed;
  z-index: 99;
  top: 271px;
  right: 0;
  background-color: black;
}

/* Upload file styles */

.fileContainer {
  display: flex;
  border-radius: 5px;
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: 12px;
  margin-left: 2px;
  margin-top: 5px;
  margin-right: 2px;
  font-weight: 200;
  background-color: #2d69eb;
  color: #fff;
  padding: 0px 6px;
  cursor: pointer;
}

.fileContainer [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.fileContainer > input {
  outline: none;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.fileinput {
  cursor: pointer;
}

.ag-theme-balham .ag-rich-select {
  background-color: #f5f7f7;
  /* height: 90px; */
}

.ag-theme-balham .ag-rich-select .ag-rich-select-list {
  height: auto !important;
}

.ag-header-group-cell-label {
  justify-content: center;
}

.ag-header-cell-label {
  justify-content: center;
}

.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  transform: rotate(360deg);
  right: 2rem;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
  /* min-height: 64px; */
  width: 103%;
}

.MuiExpansionPanelSummary-expandIcon.Mui-expanded {
  /* transform: rotate(180deg); */
  right: 2.5rem;
}

.MuiIconButton-label {
  /* color: white !important; */
  font-weight: 800;
}

/* input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
} */

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

a.dropdown-toggle.nav-link::after {
  display: block;
  position: absolute;
  top: 50% !important;
  right: 27px !important;
  transform: translateY(-50%);
}

a.dropdown-toggle.nav-link {
  width: 4rem !important;
}

li.dropdown.nav-item {
  list-style-type: none !important;
  padding-right: 10px !important;
}

#sidebar ul li.active > a,
a[aria-expanded="false"] {
  border-right: none !important;
  background: white !important;
}

.chartThumbnail {
  display: flex;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 11px;
  height: 11px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.thumbnailBox {
  width: 150px;
  height: 100px;
  border: 1px solid grey;
}

.table-legends-container {
  display: inline-flex;
}
.table-legends {
  width: 15px;
  height: 15px;
  display: inline-flex;
  align-self: center;
}

/*  end  */
