.MainTabConatiner > .nav-tabs {
  border: 0 !important;
  width: 70%;
  margin: auto;
  margin-bottom: 1rem;
  justify-content: center;
}
.MainTabConatiner > .nav-link {
  display: block;
  padding: 0rem !important;
}
.MainTabConatiner > .nav-tabs .nav-link {
  display: flex;
  justify-content: center;
  border: 1px solid #2d69eb;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  width: 31%;
}
.MainTabConatiner > .nav-tabs .nav-link:focus,
.MainTabConatiner > .nav-tabs .nav-link:hover {
  border-color: #2d69eb;
}
.MainTabConatiner > .nav-tabs .nav-item.show .nav-link,
.MainTabConatiner > .nav-tabs .nav-link.active {
  color: white;
  background-color: #2d69eb;
  border: 1px solid #2d69eb;
}
