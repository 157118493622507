.BarChartComponent-Coantainer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 700px; */
    height: 400px;
} 

.LineChartComponent-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
}