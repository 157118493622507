.leftBgImg {
  background-image: url(/img/BG_Blank.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 40%; */
  height: 100%;
  display: inline-block;
  flex-direction: column;
}
.footer-copyright {
  position: absolute;
  bottom: 0;
  left: 115px;
  /* width: 40%; */
  /* text-align: center */
}
.btnContact {
  margin-left: 31%;
  background: #2d69eb;

  margin-top: 100px;
  text-align: center;
  color: white;
  cursor: pointer;
}
#forgtpas {
  float: right;
}

.assortmentDiv {
  text-align: center;
}

p.assortmentDescription {
  color: white;
  padding-left: 74px;
  padding-right: 68px;
  font-size: 20px;
}

h6.assortmentTextLeft {
  font-size: 20px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
}

#logoImgLeft {
  width: 20%;
  padding-top: 15px;
}
button.btn.btn-primary.btn-square.shadow-sm.btnforgetpassword {
  color: #2d69eb;
  background-color: white;
  border-color: #2e6da4;
}
button.btn.btn-primary.btn-square.shadow-sm.btnsignin {
  padding-right: 42px;
  padding-left: 40px;
  margin-top: 20px;
  background: #2d69eb;
  color: white;
  cursor: pointer;
}
.btn-square.btnforgotpass {
  color: #2d69eb;
}
button.btn.btn-primary.btn-square.shadow-sm.getStartedbtn {
  color: #2d69eb;
  background-color: white;
  border-color: #2e6da4;
}
button.btn.btn-square.shadow-sm.dontHaveAnAccount {
  color: #2d69eb;
  background-color: white;
}
button.btn.btn-primary.btn-square.shadow-sm.btncontact {
  margin-left: 31%;
  background: #2d69eb;
  margin-top: 123px;
  text-align: center;
  color: white;
  font-size: 11px;
}
.btn-primary {
  color: #fff;
  background-color: #2d69eb;
  border-color: #2e6da4;
}

.logoareaLeftText {
  margin-top: 170px;
  text-align: center;
}

div#subdiv {
  margin-top: 60px;
}

p.emailAddress {
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
}

p.enterDetails {
  font-size: 14px;
  font-family: inherit;
  font-weight: 400;
  margin-top: 4%;
}

p.signIn {
  font-size: 25px;
  font-family: inherit;
  font-weight: 400;
  padding-top: 60px;
}

p.emailPassword {
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
}

.loginDiv.text-center {
  background: white;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

h5,
h6 {
  font-family: Arial, Helvetica, sans-serif;
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}

.form-control {
  line-height: 2;
  /* width: 60%; */
}

#btndiv2 {
  margin-top: -12px;
  margin-bottom: 1%;
}
#btndiv3 {
  margin-top: -12px;
  margin-bottom: 1%;
  justify-content: space-between;
  display: flex;
}

/* button.btn.btn-primary.btn-square.shadow-sm.btncontact {
    padding-right: 42px;
    padding-left: 40px;
    background-color: #2d69eb;
} */

@media only screen and (max-width: 480px) {
  h4#signIntext {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}

.btn-round {
  border-radius: 30px;
}
.text-account {
  color: #2d69eb;
}
.text-blue {
  color: #2d69eb;
  font-size: 13px;
  float: right;
  margin-top: -51%;
}

form.border.rounded.p-5.formWidth {
  background-color: #ffffff;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
}

p.text-white.copyrighttext {
  text-align: center;
}

.inputBorder {
  border: 1px solid #cacfe7;
  border-radius: 4px !important;
  margin-top: 10px;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}

.inputBorder:hover {
  border-color: #3096d8;
}
.belkLogo {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10rem;
}
/* .btnalignment {
    margin-left: 12rem
} */
